import { useEffect } from 'react';
import { navigate } from 'gatsby';

// markup
function NotFoundPage() {
    useEffect(() => {
        navigate('/', { replace: true });
    });
}

export default NotFoundPage;
